const dev = {
	API_ENDPOINT_URL: 'http://127.0.0.1:8001/api',
	API_PUBLIC_URL: 'http://127.0.0.1:8001',
	API_ASSET_URL: 'http://127.0.0.1:8001',
	WEBSOCKETS_HOST: '127.0.0.1',
	WEBSOCKETS_PORT: '6001',
	PRINTER_URL: 'http://localhost:7485/print',
};

const dev2 = {
	API_ENDPOINT_URL: '/api'
};

const prod = {
	API_ENDPOINT_URL: 'http://127.0.0.1:8001/api',
	API_PUBLIC_URL: 'http://127.0.0.1:8001',
	API_ASSET_URL: 'http://127.0.0.1:8001',
	WEBSOCKETS_HOST: '127.0.0.1',
	WEBSOCKETS_PORT: '6001',
	PRINTER_URL: 'http://localhost:7485/print',
};

const test = {
	API_ENDPOINT_URL: '/api'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
