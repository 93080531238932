// In a file named notificationSlice.js
import { createSlice} from '@reduxjs/toolkit';
import { notification } from 'antd';

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {},
  reducers: {
    showNotification: (state, action) => {
      const { type, message, description, duration } = action.payload;
      notification[type]({
        message,
        description,
        duration,
      });
    },
  },
});

export const { showNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
