import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import user from './slices/userSlice'
import product from './slices/productSlice'
import categories from './slices/categorySlice'
import businesses from './slices/businessesSlice'
import notificationSlice from './slices/notificationSlice'
import networkStatus from './slices/networkStatusSlice'
import outlets from './slices/outletSlice'
import products from './slices/productSlice'
import suppliers from './slices/supplierSlice'
import taxes from './slices/taxSlice'
import unitOfMeasures from './slices/unitOfMeasureSlice'
import orderDetails from './slices/orderSlice'
import inventoryProducts from './slices/inventoryProductsSlice'
import cartDetails from './slices/cartSlice'
import customerDetails from './slices/customerSlice'
import paymentMethodDetails from './slices/paymentMethodSlice'
import printers from './slices/printerSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        user,
        product,
        categories,
        notificationSlice,
        networkStatus,
        businesses,
        outlets,
        products,
        suppliers,
        taxes,
        unitOfMeasures,
        orderDetails,
        inventoryProducts,
        cartDetails,
        customerDetails,
        paymentMethodDetails,
        printers,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
