import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice} from '@reduxjs/toolkit';
import fetch from 'auth/FetchInterceptor';
export const initialState = {
  businesses: [],
};

const businesseslice = createSlice({
  name: 'businesses',
  initialState,
  reducers: {
    setBusinesses: (state, action) => {
      state.businesses = action.payload;
    },
    clearBusinesses: (state) => {
      state.businesses = [];
    },
    refreshBusinesses: (state, action) => {
      const { id, newItem, actionType } = action.payload;
      state.businesses = state.businesses.map(item => {        
        if (item.id === id) {
          if (actionType === 'update') {
            return newItem;
          } else if (actionType === 'delete') {
            return null; // Returning null will remove the item from the array
          }
        }
        return item;
      }).filter(item => item !== null); // Filter out deleted items
    },
  },
});

 
 
// export const createCategory = createAsyncThunk(
//     'categories/store',
//     async (obj, { rejectWithValue,dispatch,queryClient }) => {
//         console.log('obj',obj)
//       try {      
//         const productId = Date.now(); 
//         const categories = { [productId]: obj };   
//       //  dispatch(setCategories(obj));
//         await fetch({
//           url: '/categories',
//           method: 'post',
//           data: obj,
//         });   
//         queryClient.invalidateQueries('categories')    
//         return obj; // Return the created product data
//       } catch (err) {
//         return rejectWithValue(err.response?.data?.message || 'Error');
//       }
//     }
//   );


export const { setBusinesses, clearBusinesses,refreshBusinesses } = businesseslice.actions;
export default businesseslice.reducer;
