import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isConnected: true,
};

export const networkStatusSlice = createSlice({
  name: 'networkStatus',
  initialState,
  reducers: {
    setNetworkStatus: (state, action) => {
      state.isConnected = action.payload;
    },
  },
});

export const { setNetworkStatus } = networkStatusSlice.actions;
export const selectIsConnected = (state) => state.networkStatus.isConnected;

export default networkStatusSlice.reducer;
