import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setNetworkStatus } from 'store/slices/networkStatusSlice';
import { notification } from 'antd';
import echo from './Echo'; // Ensure this is correctly imported
import { API_ENDPOINT_URL } from 'configs/AppConfig';
import fetch from 'auth/FetchInterceptor';
import { ProcessRecords } from 'services/OfflineAPIRequestHandler'; // Import the ProcessRecords function
import { useQueryClient } from '@tanstack/react-query'; // Import useQueryClient

const CHECK_INTERVAL = 5000; // Check every 5 seconds
const API_URL = `${API_ENDPOINT_URL}/heartbeat`;

const NetworkStatusManager = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient(); // Initialize queryClient
    const [hasBeenOffline, setHasBeenOffline] = useState(false);
    const [lastNotificationType, setLastNotificationType] = useState(null);

    const queryKeys = ['products', 'orders']; // Add your query keys here

    const openNotificationWithIcon = (type, message, description) => {
        if (type === lastNotificationType) return; // Prevent duplicate notifications
        notification[type]({
            message,
            description,
        });
        setLastNotificationType(type);
    };

    const getUserAgentDetails = () => {
        const userAgent = navigator.userAgent;
        let browserName = 'Unknown';
        let osName = 'Unknown';

        if (userAgent.match(/chrome|chromium|crios/i)) {
            browserName = 'Chrome';
        } else if (userAgent.match(/firefox|fxios/i)) {
            browserName = 'Firefox';
        } else if (userAgent.match(/safari/i)) {
            browserName = 'Safari';
        } else if (userAgent.match(/opr\//i)) {
            browserName = 'Opera';
        } else if (userAgent.match(/edg/i)) {
            browserName = 'Edge';
        }

        if (userAgent.match(/win/i)) {
            osName = 'Windows';
        } else if (userAgent.match(/mac/i)) {
            osName = 'MacOS';
        } else if (userAgent.match(/linux/i)) {
            osName = 'Linux';
        } else if (userAgent.match(/android/i)) {
            osName = 'Android';
        } else if (userAgent.match(/iphone|ipad|ipod/i)) {
            osName = 'iOS';
        }

        return { userAgent, browserName, osName };
    };

    useEffect(() => {
        const updateNetworkStatus = (isOnline) => {
            dispatch(setNetworkStatus(isOnline));
        };

        const sendHeartbeat = async () => {
            const { userAgent, browserName, osName } = getUserAgentDetails();

            try {
                const systemInfo = JSON.parse(localStorage?.getItem("auth_user"));
                const payload = {
                    user_agent: userAgent,
                    os: osName,
                    browser: browserName,
                    systemInfo: systemInfo?.systemInfo[0]
                }

                const response = await fetch({
                    url: API_URL,
                    method: 'post',
                    data: payload
                });
                if (response.status === 'ok') {
                    updateNetworkStatus(true);
                    openNotificationWithIcon('success', 'Online Mode', 'You are online!');
                    ProcessRecords(queryClient, queryKeys); // Call ProcessRecords when online
                } else {
                    updateNetworkStatus(false);
                    openNotificationWithIcon('error', 'Offline Mode', 'You are offline!');
                }
            } catch (error) {
                updateNetworkStatus(false);
            }
        };

        // Listen to the Echo events for network status
        const pusherConnection = echo.connector.pusher.connection;

        pusherConnection.bind('state_change', (states) => {
            console.log('Pusher state changed:', states);
            if (states.current === 'connected') {
                if (hasBeenOffline) {
                    openNotificationWithIcon('success', 'Online Mode', 'You are online!');
                    ProcessRecords(queryClient, queryKeys); // Call ProcessRecords when online
                }
                updateNetworkStatus(true); // Update network status to online
            } else {
                if (!hasBeenOffline || states.current === 'disconnected') {
                    openNotificationWithIcon('error', 'Offline Mode', 'You are offline!');
                }
                setHasBeenOffline(true); // Mark that the user has been offline at least once
                updateNetworkStatus(false); // Update network status to offline
            }
        });

        pusherConnection.bind('error', (err) => {
            console.error('Pusher error:', err);
            openNotificationWithIcon('error', 'Connection Error', 'There was an error with the connection!');
            updateNetworkStatus(false); // Update network status to offline
        });

        // Initial status check
        sendHeartbeat();

        // Set interval to check periodically
        const interval = setInterval(sendHeartbeat, CHECK_INTERVAL);

        return () => {
            pusherConnection.unbind('state_change');
            pusherConnection.unbind('error');
            clearInterval(interval);
        };
    }, [dispatch, hasBeenOffline, lastNotificationType, queryClient]);

    return null; // This component does not render anything
};

export default NetworkStatusManager;
