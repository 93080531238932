import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  taxes: [],
};

const taxSlice = createSlice({
  name: 'taxes',
  initialState,
  reducers: {
    setTaxes: (state, action) => {
      state.taxes = action.payload;
    },
    clearTaxes: (state) => {
      state.taxes = [];
    },
    refreshTaxes: (state, action) => {
      const { id, newItem, actionType } = action.payload;
      state.taxes = state.taxes.map(item => {
        if (item.id === id) {
          if (actionType === 'update') {
            return newItem;
          } else if (actionType === 'delete') {
            return null; // Returning null will remove the item from the array
          }
        }
        return item;
      }).filter(item => item !== null); // Filter out deleted items
    },
  },
});

export const { setTaxes, clearTaxes, refreshTaxes } = taxSlice.actions;
export default taxSlice.reducer;
