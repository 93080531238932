import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  inventoryProducts: [],
};

const inventoryProductSlice = createSlice({
  name: 'inventoryProducts',
  initialState,
  reducers: {
    setInventoryProducts: (state, action) => {
      state.inventoryProducts = action.payload;
    },
    clearInventoryProducts: (state) => {
      state.inventoryProducts = [];
    },
    refreshInventoryProducts: (state, action) => {
      const { id, newItem, actionType } = action.payload;
      state.inventoryProducts = state.inventoryProducts.map(item => {        
        if (item.id === id) {
          if (actionType === 'update') {
            return newItem;
          } else if (actionType === 'delete') {
            return null; // Returning null will remove the item from the array
          }
        }
        return item;
      }).filter(item => item !== null); // Filter out deleted items
    },
  },
});

export const { setInventoryProducts, clearInventoryProducts, refreshInventoryProducts } = inventoryProductSlice.actions;
export default inventoryProductSlice.reducer;
