import { createSlice } from '@reduxjs/toolkit';
import { AUTH_USER } from 'constants/AuthConstant';

const loadUserFromStorage = () => {
  try {
    const serializedUser = localStorage.getItem(AUTH_USER);
    if (serializedUser === null) {
      return undefined;
    }
    return JSON.parse(serializedUser);
  } catch (error) {
    console.error('Error loading user from localStorage:', error);
    return undefined;
  }
};

const saveUserToStorage = (user) => {
  try {
    const serializedUser = JSON.stringify(user);
    localStorage.setItem(AUTH_USER, serializedUser);
  } catch (error) {
    console.error('Error saving user to localStorage:', error);
  }
};

const initialState = loadUserFromStorage() || {
  id: null,
  name: '',
  email: '',
  username: '',
  // abilities: [],
  token: '',
  type: '',
  role: [],
  outlets: [],
  systemInfo: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { id, name, email, username, type, role, outlets, systemInfo } = action.payload.user;
      // const { abilities } = action.payload.decodedToken;
      const token = action.payload.encodedToken;
      state.id = id;
      state.name = name;
      state.email = email;
      state.username = username || ''; // Assuming username might be null
      //    state.abilities = abilities;
      state.token = token;
      state.type = type;
      state.role = role;
      state.outlets = outlets;
      state.systemInfo = systemInfo;
      saveUserToStorage(state); // Save user to localStorage
    },
    clearUser: (state) => {
      state.id = null;
      state.name = '';
      state.email = '';
      state.username = '';
      //   state.abilities = [];
      state.token = '';
      state.type = '';
      state.role = [];
      state.outlets = [];
      state.systemInfo = [];
      localStorage.removeItem('user'); // Remove user from localStorage
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
