import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_TOKEN,OUTLET_ID,OUTLET_LOGO,AUTH_USER,SOAK_ID,TRANSPORT_KEYS,SOAK_DATA} from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import AuthService from 'services/AuthService';
import { setUser, clearUser } from './userSlice';
import echo from 'services/Echo';


export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
}

 
export const signIn = createAsyncThunk('login',async (data, { rejectWithValue,dispatch }) => {

	const { email, password } = data;

	const setEchoToken = (token) => {
		if(echo.options){
		  if(echo.options.auth){
			echo.options.auth.headers['Authorization'] = `Bearer ${token}`
		  } else {
			let auth = { headers: { Authorization: `Bearer ${token}`, Accept: 'application/json', } }
			echo.options = { ...echo.options, auth: auth }
		  }
		}
	  }
	
	
	try {

		const response = await AuthService.login({email, password})
		const token = response.data.encodedToken;	
		const outlet_id = response.data.user.outlets[0].identifier_id;	
		localStorage.setItem(AUTH_TOKEN, token);	
		localStorage.setItem(OUTLET_ID, outlet_id);	
		localStorage.setItem(OUTLET_LOGO, response.data.user.outlets[0].logo);
		localStorage.setItem(SOAK_ID, response.data.soakID);	
		localStorage.setItem(TRANSPORT_KEYS, JSON.stringify(response.data.keys));	
		localStorage.setItem(SOAK_DATA, JSON.stringify(response.data.soakData));
		setEchoToken(token)	// fix token issue for Echo
		dispatch(setUser(response.data)); 
		return token;
	} catch (err) {

		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signUp = createAsyncThunk('register',async (data, { rejectWithValue }) => {
	const { email, password } = data
	try {
		const response = await AuthService.register({email, password})
		const token = response.data.token;		
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

 
export const signOut = createAsyncThunk('logout',async (dispatch ) => {
    const response = await AuthService.logout()
	localStorage.removeItem(AUTH_TOKEN);
	localStorage.removeItem(AUTH_USER);
	localStorage.removeItem(OUTLET_ID);	
	localStorage.removeItem(OUTLET_LOGO);
	localStorage.removeItem(SOAK_ID);
	localStorage.removeItem(TRANSPORT_KEYS);
	localStorage.removeItem(SOAK_DATA);

	dispatch(clearUser());
    return response.data
})

export const changeOutlet = createAsyncThunk('changeOutlet',async (data, { rejectWithValue }) => {
	try {
		const response = await AuthService.changeOutlet({data})
		if(response.type == 'success'){
			localStorage.setItem(OUTLET_ID, response.data.identifier_id);
			localStorage.setItem(OUTLET_LOGO, response.data.logo);
			window.location.reload();
		} else {
			return rejectWithValue(response.message)
		}
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signInWithGoogle = createAsyncThunk('signInWithGoogle', async (_, { rejectWithValue }) => {
    try {
		const response = await AuthService.loginInOAuth()
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signInWithFacebook = createAsyncThunk('signInWithFacebook', async (_, { rejectWithValue }) => {
    try {
		const response = await AuthService.loginInOAuth()
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithGoogle.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithGoogle.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithGoogle.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithFacebook.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithFacebook.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithFacebook.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const { 
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess	
} = authSlice.actions

export default authSlice.reducer