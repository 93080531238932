import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';

const initialState = {
  viewOrder: false,
  newOrder: false,
  changeOrder: false,
  registers: [],
  sessionID: '',
  orderTypes: [], // all ordertypes (tabs assigned to the outlet)
  orderType: '', // selected ordertype (tab)
  orders: [], // all orders
  selectedOrder: {},
  orderCounter: 0, // Add this line
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setViewOrder: (state, action) => {
      state.viewOrder = action.payload;
    },
    clearViewOrder: (state) => {
      state.viewOrder = false;
    },
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    clearOrders: (state) => {
      state.orders = [];
    },
    setNewOrder: (state, action) => {
      state.newOrder = action.payload;
    },
    clearNewOrder: (state) => {
      state.newOrder = false;
    },
    setChangeOrder: (state, action) => {
      state.changeOrder = action.payload;
    },
    clearChangeOrder: (state) => {
      state.changeOrder = false;
    },
    setRegisters: (state, action) => {
      state.registers = action.payload;
    },
    clearRegisters: (state) => {
      state.registers = [];
    },
    setSessionID: (state, action) => {
      state.sessionID = action.payload;
    },
    clearSessionID: (state) => {
      state.sessionID = '';
    },
    setOrderTypes: (state, action) => {
      state.orderTypes = action.payload;
    },
    clearOrderTypes: (state) => {
      state.orderTypes = [];
    },
    setOrderType: (state, action) => {
      state.orderType = action.payload;
    },
    clearOrderType: (state) => {
      state.orderType = '';
    },
    setSelectedOrder: (state, action) => {
      state.selectedOrder = action.payload;
    },
    clearSelectedOrder: (state) => {
      state.selectedOrder = {};
    },
    incrementOrderCounter: (state) => { // Add this reducer
      state.orderCounter += 1;
    },
    addProductToOrder: (state, action) => {
      const product = action.payload;
      state.selectedOrder.items.push(product);
      state.selectedOrder.subTotal += product.unitPrice * product.quantity;
      state.selectedOrder.gst = state.selectedOrder.subTotal * 0.08;
      state.selectedOrder.total = state.selectedOrder.subTotal + state.selectedOrder.gst;
    },
    updateOrderStatus: (state, action) => {
      const { orderId, statusTemplateId, statusTemplateName, statusTemplateColor } = action.payload;
      const order = state.orders.find(order => order.id === orderId);
      if (order) {
        order.order_status_id = statusTemplateId;
        order.status_template = {
          id: statusTemplateId,
          name: statusTemplateName,
          color: statusTemplateColor
        };
      }
      if (state.selectedOrder.id === orderId) {
        state.selectedOrder.order_status_id = statusTemplateId;
        state.selectedOrder.status_template = {
          id: statusTemplateId,
          name: statusTemplateName,
          color: statusTemplateColor
        };
      }
    },
    setTaxDetails: (state, action) => {
      state.selectedOrder.tax_details = action.payload;
    },
  },
});

export const {
  setViewOrder, clearViewOrder, setOrders, clearOrders, setNewOrder, clearNewOrder, setChangeOrder, clearChangeOrder,
  setRegisters, clearRegisters, setSessionID, clearSessionID, setOrderTypes, clearOrderTypes,
  setOrderType, clearOrderType, setSelectedOrder, clearSelectedOrder, addProductToOrder, updateOrderStatus, setTaxDetails,
  incrementOrderCounter
} = orderSlice.actions;
export default orderSlice.reducer;



