import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  customers: [],
};

const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    clearCustomers: (state) => {
      state.customers = [];
    },
    refreshCustomers: (state, action) => {
      const { id, newItem, actionType } = action.payload;
      state.customers = state.customers.map(item => {
        if (item.id === id) {
          if (actionType === 'update') {
            return newItem;
          } else if (actionType === 'delete') {
            return null; // Returning null will remove the item from the array
          }
        }
        return item;
      }).filter(item => item !== null); // Filter out deleted items
    },
  },
});

export const { setCustomers, clearCustomers, refreshCustomers } = customerSlice.actions;
export default customerSlice.reducer;
