import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice} from '@reduxjs/toolkit';
import fetch from 'auth/FetchInterceptor';
export const initialState = {
  suppliers: [],
};

const supplierSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    setSuppliers: (state, action) => {
      state.suppliers = action.payload;
    },
    clearSuppliers: (state) => {
      state.suppliers = [];
    },
    refreshSuppliers: (state, action) => {
      const { id, newItem, actionType } = action.payload;
      state.suppliers = state.suppliers.map(item => {        
        if (item.id === id) {
          if (actionType === 'update') {
            return newItem;
          } else if (actionType === 'delete') {
            return null; // Returning null will remove the item from the array
          }
        }
        return item;
      }).filter(item => item !== null); // Filter out deleted items
    },
  },
});

  

export const { setSuppliers, clearSuppliers,refreshSuppliers } = supplierSlice.actions;
export default supplierSlice.reducer;
