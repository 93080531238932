import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.index',
        path: `${APP_PREFIX_PATH}/dashboards/index`,
        component: React.lazy(() => import('views/app-views/dashboards/index')),
    },
    {
        key: 'registers.index',
        path: `${APP_PREFIX_PATH}/sales/registers/index`,
        component: React.lazy(() => import('views/app-views/sales/registers/index')),
    },
    {
        key: 'registers.details',
        path: `${APP_PREFIX_PATH}/sales/registers/details`,
        component: React.lazy(() => import('views/app-views/sales/registers/details')),
    }, {
        key: 'sales.index',
        path: `${APP_PREFIX_PATH}/sales/orders/index`,
        component: React.lazy(() => import('views/app-views/sales/orders/index')),
    },
    {
        key: 'inventory.products',
        path: `${APP_PREFIX_PATH}/inventory/products`,
        component: React.lazy(() => import('views/app-views/inventory/products/index')),
    },
    {
        key: 'sales.orders',
        path: `${APP_PREFIX_PATH}/sales/orders`,
        component: React.lazy(() => import('views/app-views/salesOLDWithPrinter/orders')),
    },
    {
        key: 'finance.index',
        path: `${APP_PREFIX_PATH}/sales/orders`,
        component: React.lazy(() => import('views/app-views/salesOLDWithPrinter/orders')),
    },
    {
        key: 'master.categories',
        path: `${APP_PREFIX_PATH}/master/categories`,
        component: React.lazy(() => import('views/app-views/master/categories')),
    },
    {
        key: 'master.outlets',
        path: `${APP_PREFIX_PATH}/master/outlets`,
        component: React.lazy(() => import('views/app-views/master/outlets')),
    },
    {
        key: 'master.businesses',
        path: `${APP_PREFIX_PATH}/master/businesses`,
        component: React.lazy(() => import('views/app-views/master/businesses')),
    },
    {
        key: 'master.floorplan',
        path: `${APP_PREFIX_PATH}/master/floorplan`,
        component: React.lazy(() => import('views/app-views/master/floorplan')),
    },


    //



]