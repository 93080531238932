// src/store/slices/cartSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cart: {
    items: [],
    currency: '', // Add currency to the initial state
  }
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCart: (state, action) => {
      state.cart.items = action.payload.items;
      state.cart.currency = action.payload.currency; // Set currency
    },
    getCart: (state) => {
      return state.cart.items;
    },
    refreshCart: (state) => {
      state.cart.items = [];
      state.cart.currency = ''; // Clear currency
    },
    addProductToCart: (state, action) => {
      const { product, options, currency, unit_of_measure } = action.payload;
      const existingItem = state.cart.items.find(item => item.product.id === product.id && JSON.stringify(item.options) === JSON.stringify(options));
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.cart.items.push({ product, options, quantity: 1, unit_of_measure });
      }
      state.cart.currency = currency; // Set currency
    },
    updateQuantity: (state, action) => {
      const { productId, quantity, options } = action.payload;
      state.cart.items = state.cart.items.map(item =>
        item.product.id === productId && JSON.stringify(item.options) === JSON.stringify(options)
          ? { ...item, quantity }
          : item
      );
    },
    updateUnitOfMeasure: (state, action) => {
      const { productId, unit_of_measure, options } = action.payload;
      state.cart.items = state.cart.items.map(item =>
        item.product.id === productId && JSON.stringify(item.options) === JSON.stringify(options)
          ? { ...item, unit_of_measure }
          : item
      );
    },
    removeProductFromCart: (state, action) => {
      const { productId, options } = action.payload;
      state.cart.items = state.cart.items.filter(item => !(item.product.id === productId && JSON.stringify(item.options) === JSON.stringify(options)));
    },
    clearProductCart: (state, action) => {
      state.cart.items = [];
    },
    updateDiscount: (state, action) => {
      const { productId, discount, discountType, options } = action.payload;
      state.cart.items = state.cart.items.map(item =>
        item.product.id === productId && JSON.stringify(item.options) === JSON.stringify(options)
          ? { ...item, discount, discountType }
          : item
      );
    },
    setCurrency: (state, action) => {
      state.cart.currency = action.payload;
    },
  },
});

export const { setCart, getCart, refreshCart, addProductToCart, updateQuantity, updateUnitOfMeasure, clearProductCart, removeProductFromCart, updateDiscount, setCurrency } = cartSlice.actions;
export default cartSlice.reducer;
