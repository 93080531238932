import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    printerProvider: 'recta',
    printerSpool: [],
    printerAssigned: false, // Track if the printer permission is granted
    printerPort: null,
};

const printerSlice = createSlice({
    name: 'printer',
    initialState,
    reducers: {
        setPrinterSpool: (state, action) => {
            state.printerSpool = action.payload;
        },
        clearPrinterSpool: (state) => {
            state.printerSpool = [];
        },
        setPrinterAssigned: (state, action) => {
            state.printerAssigned = action.payload;
        },
        setPrinterPort: (state, action) => {
            state.printerPort = action.payload;
        },
        clearPrinterPort: (state) => {
            state.printerPort = null;
        },
        setPrinterProvider: (state, action) => {
            state.printerProvider = action.payload;
        },
        clearPrinterProvider: (state) => {
            state.printerProvider = '';
        }
    },
});

export const { setPrinterSpool, clearPrinterSpool, setPrinterAssigned, setPrinterPort, clearPrinterPort, setPrinterProvider, clearPrinterProvider } = printerSlice.actions;
export default printerSlice.reducer;
