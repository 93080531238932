import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice} from '@reduxjs/toolkit';
import fetch from 'auth/FetchInterceptor';
export const initialState = {
  categories: [],
};

const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories: (state, action) => {
      // Merge the existing product data with the new data
      console.log("Setting categories with payload:", action.payload);
      state.categories = action.payload;
      // state.categories = [ ...state.categories, ...action.payload ];
    },
    clearCategories: (state) => {
      state.categories = [];
    },
    refreshCategories: (state, action) => {
      const { id, newItem, actionType } = action.payload;
      console.log('item---------',newItem)
      // Update the categories based on the action type
      state.categories = state.categories.map(item => {        
        if (item.id === id) {
          if (actionType === 'update') {
            return newItem;
          } else if (actionType === 'delete') {
            return null; // Returning null will remove the item from the array
          }
        }
        return item;
      }).filter(item => item !== null); // Filter out deleted items
     // state.categories = [];
    },
  },
});

 
 
export const createCategory = createAsyncThunk(
    'categories/store',
    async (obj, { rejectWithValue,dispatch,queryClient }) => {
        console.log('obj',obj)
      try {      
        const productId = Date.now(); 
        const categories = { [productId]: obj };   
      //  dispatch(setCategories(obj));
        await fetch({
          url: '/categories',
          method: 'post',
          data: obj,
        });   
        queryClient.invalidateQueries('categories')    
        return obj; // Return the created product data
      } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error');
      }
    }
  );


export const { setCategories, clearCategories,refreshCategories } = categorySlice.actions;
export default categorySlice.reducer;
